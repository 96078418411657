import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";

import ErrorIcon from "@mui/icons-material/Error";
import HelpIcon from "@mui/icons-material/Help";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { AppWorkoutWithSummary } from "@volley/data";
import { convert, model, sim } from "@volley/physics";
import { getPhysicsModel } from "@volley/physics/dist/conversions";
import type { ServePosition } from "@volley/shared/apps/app-common-models";
import type {
    ServeAndVolleyParameters,
    DifficultyLevel,
    ServeAndVolleyAppConfig,
} from "@volley/shared/apps/serveandvolley-models";
import { JSONObject } from "@volley/shared/common-models";

import logger from "../../../../../log";
import { logFetchError } from "../../../../../util/fetchApi";
import type { CoordWithSys } from "../../../../../util/position-types";
import useDialog from "../../../../Dialog/useDialog";
import Loading from "../../../../common/Loading";
import ResizableWorkoutVisualizer from "../../../../common/Visualizer/ResizableWorkoutVisualizer";
import { VisualizerAOI } from "../../../../common/Visualizer/models";
import { Sport, useSelectedSport } from "../../../../common/context/sport";
import { usePhysicsModelContext } from "../../../../hooks/PhysicsModelProvider";
import { useCurrentUser } from "../../../../hooks/currentUser";
import { useStatus } from "../../../../hooks/status";
import useIntercom from "../../../../hooks/useIntercom";
import { LiftModal, useLift } from "../../../../hooks/useLift";
import usePosition from "../../../../hooks/usePosition";
import usePrevious from "../../../../hooks/usePrevious";
import { useTrainerFeatures } from "../../../../hooks/useTrainerFeatures";
import BasicLevelSelector from "../../Shared/BasicLevelSelector";
import CaptureToast from "../../Shared/CaptureToast";
import DelaySlider from "../../Shared/DelaySlider";
import OptionSelector from "../../Shared/OptionSelector";
import PlayAppBar from "../../Shared/PlayAppBar";
import SpeedAdjustment from "../../Shared/SpeedAdjustment";
import useAppWorkouts from "../../db";
import useAppWorkoutPlay from "../../useAppWorkoutPlay";
import WorkoutErrorDialog from "../4-multi-shot/play/ErrorDialog";
import { overlayDefaultVisionPipelineConfig } from "../shared/app-common";

import ErrorDialog from "./ErrorDialog";
import InstructionDialog from "./InstructionDialog";
import LocalizingDialog from "./LocalizingDialog";
import ServePlus1LevelSelector from "./ServePlus1Selector";
import ShotSelector from "./ShotSelector";
import VisionSystemStartingDialog from "./VIsionSystemStartingDialog";
import WorkflowSteps from "./WorkflowSteps";

const playerPosition: Partial<
    Record<Sport, Record<ServePosition, CoordWithSys>>
> = {
    TENNIS: {
        deuce: {
            x: -2,
            y: 12.2,
            sys: "physics",
        },
        ad: {
            x: 2,
            y: 12.2,
            sys: "physics",
        },
    },
    PLATFORM_TENNIS: {
        deuce: {
            x: 2000,
            y: -400,
            sys: "court",
        },
        ad: {
            x: 4100,
            y: -400,
            sys: "court",
        },
    },
};

const summaryText = "summary";

function getDefaultParams(
    workout: AppWorkoutWithSummary,
    trainerFeatures: string[],
): ServeAndVolleyParameters {
    const hasPoseServeDetection =
        trainerFeatures.includes("poseServeDetection");

    const ac = workout.config as unknown as ServeAndVolleyAppConfig;

    return {
        shots: ac.shots[1],
        difficultyLevel: 1,
        shotDelayMilliseconds: hasPoseServeDetection ? 500 : 0,
        servePosition: "deuce",
        serveAOI: ac.serveAOI.deuce,
        serveExitAOI: ac.exitAOI.deuce,
        targetAOI: ac.targetAOI.deuce,
    };
}

type ParamsAction =
    | { type: "difficultyLevel"; value: DifficultyLevel }
    | { type: "servePosition"; value: ServePosition }
    | { type: "shotDelay"; value: number }
    | { type: "speedAdjustment"; value?: number };

function paramsReducer(
    state: ServeAndVolleyParameters,
    action: ParamsAction,
): ServeAndVolleyParameters {
    switch (action.type) {
        case "difficultyLevel":
            return {
                ...state,
                difficultyLevel: action.value,
            };
        case "servePosition": {
            return {
                ...state,
                servePosition: action.value,
            };
        }
        case "shotDelay":
            return {
                ...state,
                shotDelayMilliseconds: action.value * 1000, // convert to milliseconds
            };
        case "speedAdjustment":
            return {
                ...state,
                speedAdjustment: action.value,
            };
        default:
            return state;
    }
}

interface ServeAndVolleyProps {
    workout: AppWorkoutWithSummary;
    trainerFeatures: string[];
}

function ServeAndVolley({
    workout,
    trainerFeatures,
}: ServeAndVolleyProps): JSX.Element {
    const [paramsState, paramsDispatch] = React.useReducer(
        paramsReducer,
        getDefaultParams(workout, trainerFeatures),
    );
    const [playClicked, setPlayClicked] = React.useState<boolean>(false);
    const { status } = useStatus();
    const { selected: selectedSport } = useSelectedSport();
    const { physicsModelName } = usePhysicsModelContext();
    const workoutStatus = status?.workouts;

    const [selectedShots, setSelectedShots] = React.useState<string[]>([
        paramsState.shots[0].id,
    ]);

    const { position, isVisionStarting, isVisionFaulted, method, cancel } =
        usePosition();

    const [forceUsePlanned, setForceUsePlanned] = React.useState(false);

    const { checkForLift, stop: stopLift } = useLift();
    const visionUnavailable =
        isVisionStarting || status?.vision?.serviceState !== "Running";
    const { isAdmin } = useCurrentUser();
    const [instructionDialogOpen, setInstructionDialogOpen] =
        React.useState<boolean>(false);

    // we use this ref to track if the settings have been changed and we should start a new game
    const modifiedRef = React.useRef(true);

    // we use this ref to track if a stop has been requested
    const stopRequestedRef = React.useRef(false);

    // we use this ref to force localization
    const forceLocalizationRef = React.useRef(true);

    const [localizingDialogOpen, setLocalizingDialogOpen] =
        React.useState(false);

    const [visionErrorDialogOpen, setVisionErrorDialogOpen] =
        React.useState(false);

    const [errorDialogOpen, setErrorDialogOpen] = React.useState(false);

    const { setDialogType } = useDialog();

    const appConfig = React.useMemo<ServeAndVolleyAppConfig>(() => {
        const ac = workout.config as unknown as ServeAndVolleyAppConfig;
        if (trainerFeatures.includes("serveAndVolleyCloudDefaults")) {
            // Use vision config defaults to replace any missing DB values
            overlayDefaultVisionPipelineConfig(ac, workout.sport.name);
            // Ensure ball tracking is OFF for S&V
            // NOTE: turn this off in DB!
            // General serve and volley default overlay doesn't include it because it's V1-2 only.
            if (ac.visionPipelineConfig.ballTrackingMode === undefined) {
                ac.visionPipelineConfig.ballTrackingMode = "DISABLE";
            }
            logger.info(
                `[serveAndVolley] - App Config (post overlay): ${JSON.stringify(ac, undefined, 2)}`,
            );
        }
        return ac;
    }, [workout, trainerFeatures]);

    const workoutParams = React.useMemo(() => {
        const shots = appConfig.shots[paramsState.difficultyLevel].filter((s) =>
            selectedShots.includes(s.id),
        );
        const serveAOI = appConfig.serveAOI[paramsState.servePosition];
        const targetAOI = appConfig.targetAOI[paramsState.servePosition];
        const serveExitAOI = appConfig.exitAOI[paramsState.servePosition];
        const visionPipelineConfig = trainerFeatures.includes(
            "serveAndVolleyV3",
        )
            ? appConfig.visionPipelineConfig
            : undefined;
        const wp = {
            ...paramsState,
            shots,
            serveAOI,
            targetAOI,
            serveExitAOI,
            visionPipelineConfig,
        };
        return wp;
    }, [appConfig, paramsState, trainerFeatures, selectedShots]);

    const workoutPosition = React.useMemo(() => {
        return appConfig.trainerPosition[paramsState.servePosition];
    }, [appConfig.trainerPosition, paramsState.servePosition]);

    const plannedTrainerPosition = React.useMemo(() => {
        const pos = appConfig.trainerPosition[paramsState.servePosition];
        return {
            x: pos.positionX,
            y: pos.positionY,
            yaw: pos.positionYaw,
            heightIn: pos.positionHeight,
        };
    }, [appConfig.trainerPosition, paramsState.servePosition]);

    // Only allow 'use planned' for Tennis
    const handleUsePlanned = React.useCallback(() => {
        setLocalizingDialogOpen(false);
        setForceUsePlanned(true);
        setPlayClicked(true);
    }, []);

    // localizedPositionForWorkout
    // If we have a localization height that is different from the workout height,
    // the localizedPosition that we send to appWorkoutPlay is NOT the localized postion
    // computed by usePosition because it will have the localization height. As a result,
    // if the localization height is different from the workout height, we need to do the adjustment.
    const localizedPositionForWorkout = React.useMemo(() => {
        if (forceUsePlanned) {
            // Tennis workouts can bypass localization to handle cases were the algorithm struggles due to ambient conditions
            if (appConfig.pitchOverride || appConfig.yOffset) {
                // If the app config has pitchOverride oryOffset values, we use them to "spoof" a localization result
                logger.info(
                    `[serveAndVolley] - Converting Planned Position to Localized: ${JSON.stringify(plannedTrainerPosition)}`,
                );
                const ts = new sim.TrainerSim(
                    physicsModelName as model.PhysicsModelName,
                    selectedSport,
                );
                ts.SetPositionManual({
                    h: convert.launchHeightInches2HeadHeight(
                        plannedTrainerPosition.heightIn,
                    ),
                    x: plannedTrainerPosition.x,
                    y: plannedTrainerPosition.y - (appConfig.yOffset ?? 0),
                    yaw: plannedTrainerPosition.yaw,
                });
                const localized = ts.GetLocalizedCameraPosition();

                const masqueradePosition = {
                    x: localized.x,
                    y: localized.y,
                    z: localized.z,
                    pitch:
                        appConfig.pitchOverride ??
                        1.45 - (90.0 * Math.PI) / 180.0,
                    roll: 0,
                    yaw: localized.yaw,
                };

                logger.info(
                    `[serveAndVolley] - Sending masquerade localization position: ${JSON.stringify(masqueradePosition)}`,
                );
                return masqueradePosition;
            }

            // Otherwise, we return undefined here to use the planned position and let
            // the trainer software try to configure the pipeline based on that...
            logger.info(
                "[serveAndVolley] - Localization process bypassed, using ONLY planned position",
            );
            return undefined;
        }
        logger.info(
            `[serveAndVolley] - validaing localized position for workout (method: ${method})`,
        );
        if (
            position &&
            appConfig.localizationHeightIn &&
            plannedTrainerPosition.heightIn !==
                appConfig.localizationHeightIn &&
            method === "one-shot"
        ) {
            const fp = { ...position };
            logger.info(
                `[serveAndVolley] - localization height: ${appConfig.localizationHeightIn} in`,
            );
            logger.info(
                `[serveAndVolley] - workout height: ${plannedTrainerPosition.heightIn} in`,
            );
            logger.info(
                `[serveAndVolley] - localized with one-shot at different height than workout - adjusting localized z coord`,
            );
            const heightDiffMilli =
                (appConfig.localizationHeightIn -
                    plannedTrainerPosition.heightIn) *
                25.4;
            // the position z coord is in millimeters
            logger.info(
                `[serveAndVolley] - localized position at loc height: ${JSON.stringify(position)} in`,
            );
            fp.z = fp.z - heightDiffMilli;
            logger.info(
                `[serveAndVolley] - workout localized height is z = ${fp.z} mm (adjusted by ${heightDiffMilli})`,
            );
            return fp;
        } else if (position && method === "smart") {
            const fp = { ...position };
            logger.info(
                `[serveAndVolley] - localized with smart multi-height - adjusting localized z coord to workout height`,
            );
            const physicsModel = getPhysicsModel();
            const launchPointToCameraDistanceMeters =
                physicsModel.trainerGeometry.cameraOffsetFromHead.z -
                physicsModel.trainerGeometry.launchOriginOffset.z;
            logger.info(
                `[serveAndVolley] - launchPointToCameraDistanceMeters = ${launchPointToCameraDistanceMeters} (${launchPointToCameraDistanceMeters * 39.3701} in)`,
            );
            const plannedCameraHeightMilli =
                plannedTrainerPosition.heightIn * 25.4 +
                launchPointToCameraDistanceMeters * 1000.0;
            fp.z = plannedCameraHeightMilli;
            logger.info(
                `[serveAndVolley] - workout localized height is z = ${position.z} mm (${position.z / 25.4} in), adjusted to ${plannedCameraHeightMilli} mm (${plannedCameraHeightMilli / 25.4} in)`,
            );
            return fp;
        } else {
            return position;
        }
    }, [
        forceUsePlanned,
        method,
        position,
        appConfig.localizationHeightIn,
        appConfig.yOffset,
        appConfig.pitchOverride,
        plannedTrainerPosition,
        physicsModelName,
        selectedSport,
    ]);

    const {
        start,
        playState,
        playInitiated,
        workoutState,
        playDisabled,
        pauseDisabled,
        captureDisabled,
        captureVideo,
        captureStatus,
        stop: stopWorkout,
    } = useAppWorkoutPlay({
        workout: { ...workout, config: {}, ...workoutPosition },
        parameters: workoutParams as unknown as JSONObject,
        localizedPosition: localizedPositionForWorkout,
    });

    const handleStopWorkout = React.useCallback(async () => {
        stopRequestedRef.current = true;
        await stopWorkout();
    }, [stopWorkout]);

    const liftTargetHeight = React.useMemo(() => {
        if (playInitiated) {
            return workout?.positionHeight;
        }

        return undefined;
    }, [workout, playInitiated]);

    const handleLiftStop = React.useCallback(async () => {
        await stopLift();
        if (playState !== "stopped" || playInitiated) {
            await handleStopWorkout();
        }
    }, [handleStopWorkout, playInitiated, playState, stopLift]);

    const handlePlayClicked = React.useCallback(() => {
        stopRequestedRef.current = false;
        setLocalizingDialogOpen(true);
    }, []);

    const lastPlayState = usePrevious(playState);

    React.useEffect(() => {
        if (lastPlayState === "playing" && playState === "stopped") {
            modifiedRef.current = true;
        }
    }, [lastPlayState, playState]);

    React.useEffect(() => {
        if (
            workoutStatus?.appErrors &&
            workoutStatus?.playState === "playing" &&
            !stopRequestedRef.current
        ) {
            if (!errorDialogOpen) {
                setErrorDialogOpen(true);
            }
        }
    }, [errorDialogOpen, workoutStatus?.appErrors, workoutStatus?.playState]);

    // Start the workout when the play button is clicked
    React.useEffect(() => {
        if (playClicked && workout) {
            setPlayClicked(false);
            checkForLift();
            start().catch(logFetchError);
        }
    }, [checkForLift, playClicked, start, workout]);

    // complete player serving position
    const servePosition = React.useMemo(() => {
        return playerPosition[selectedSport]?.[paramsState.servePosition];
    }, [paramsState.servePosition, selectedSport]);

    // serve launch AOI in visualizer format for 3D render
    const visServeAOI = React.useMemo(() => {
        const aoi = appConfig.serveAOI[paramsState.servePosition];
        const visAOI: VisualizerAOI = {
            size: [
                Math.abs(aoi.lowerRightX - aoi.upperLeftX),
                Math.abs(aoi.lowerRightY - aoi.upperLeftY),
            ],
            position: {
                x: aoi.upperLeftX + (aoi.lowerRightX - aoi.upperLeftX) / 2.0,
                y: aoi.lowerRightY + (aoi.upperLeftY - aoi.lowerRightY) / 2.0,
                z: 0.05,
            },
            color: "magenta",
            opacity: 0.6,
        };
        return visAOI;
    }, [appConfig.serveAOI, paramsState.servePosition]);

    const visExitAoi = React.useMemo(() => {
        const aoi = appConfig.exitAOI[paramsState.servePosition];
        const visAOI: VisualizerAOI = {
            size: [
                Math.abs(aoi.lowerRightX - aoi.upperLeftX),
                Math.abs(aoi.lowerRightY - aoi.upperLeftY),
            ],
            position: {
                x: aoi.upperLeftX + (aoi.lowerRightX - aoi.upperLeftX) / 2.0,
                y: aoi.lowerRightY + (aoi.upperLeftY - aoi.lowerRightY) / 2.0,
                z: 0.05,
            },
            color: "yellow",
            opacity: 0.6,
        };
        return visAOI;
    }, [appConfig, paramsState.servePosition]);

    const visShots = React.useMemo(() => {
        const shots = appConfig.shots[paramsState.difficultyLevel]
            .filter((s) => selectedShots.includes(s.id))
            .map((shot) => ({
                ...shot,
                pan:
                    paramsState.servePosition === "deuce"
                        ? shot.pan
                        : -shot.pan,
            }));
        return shots;
    }, [
        appConfig.shots,
        paramsState.difficultyLevel,
        paramsState.servePosition,
        selectedShots,
    ]);

    const localizationSucceeded = React.useCallback(async () => {
        if (modifiedRef.current) {
            await stopWorkout();
            setPlayClicked(true);
        } else {
            setPlayClicked(true);
        }
    }, [stopWorkout]);

    const workoutForVisualizer = React.useMemo(
        () =>
            localizingDialogOpen
                ? undefined
                : {
                      trainer: plannedTrainerPosition,
                      player: [servePosition],
                      shots: visShots,
                      AOIs: [visServeAOI, visExitAoi],
                  },
        [
            localizingDialogOpen,
            plannedTrainerPosition,
            servePosition,
            visExitAoi,
            visShots,
            visServeAOI,
        ],
    );

    const workoutForLocalizingDialog = React.useMemo(
        () => ({
            trainer: plannedTrainerPosition,
            localized: position && {
                ...position,
                heightIn: plannedTrainerPosition.heightIn,
            },
            player: [servePosition],
            shots: visShots,
            AOIs: [visServeAOI, visExitAoi],
        }),
        [
            plannedTrainerPosition,
            position,
            servePosition,
            visShots,
            visServeAOI,
            visExitAoi,
        ],
    );

    const onChangeSpeedAdjustment = React.useCallback((value?: number) => {
        paramsDispatch({
            type: "speedAdjustment",
            value,
        });
    }, []);

    if (appConfig === null) {
        return <Typography>Loading...</Typography>;
    }

    return (
        <>
            <Stack
                spacing={0}
                sx={{
                    backgroundColor: "background.default",
                    p: 2,
                    mx: -1,
                }}
            >
                <Typography variant="h3" mb={1}>
                    {`${workout.name} (BETA)`}
                </Typography>
                <Box
                    component="div"
                    mb={2}
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                >
                    <Button onClick={() => {}} variant="text">
                        <Typography
                            variant="h4"
                            color="info.main"
                            onClick={() => setInstructionDialogOpen(true)}
                        >
                            View Instructions
                        </Typography>
                    </Button>

                    {status && isVisionFaulted && (
                        <Button
                            variant="text"
                            startIcon={<ErrorIcon />}
                            onClick={() =>
                                setDialogType("VisionFaultServeAndVolleyDialog")
                            }
                            color="error"
                        >
                            <Typography variant="h4">
                                Camera system unavailable
                            </Typography>
                        </Button>
                    )}

                    {status && isVisionStarting && (
                        <Button
                            variant="text"
                            startIcon={<HourglassEmptyIcon />}
                            onClick={() => setVisionErrorDialogOpen(true)}
                            color="warning"
                        >
                            <Typography variant="h4">
                                Camera system still starting...
                            </Typography>
                        </Button>
                    )}
                </Box>

                <Typography variant="h3" mb={2} color="primary.main">
                    Setup
                </Typography>

                <Box sx={{ mb: 1 }}>
                    <OptionSelector
                        disabled={playState === "playing"}
                        label="Server Position"
                        labelWrapperSx={{ flex: 1 }}
                        toggleButtonSx={{ flex: 2 }}
                        options={[
                            { value: "ad", label: "Ad" },
                            { value: "deuce", label: "Deuce" },
                        ]}
                        selected={paramsState.servePosition}
                        setOption={(value) => {
                            if (value !== null) {
                                paramsDispatch({
                                    type: "servePosition",
                                    value,
                                });
                                modifiedRef.current = true;
                                // force localization if we change the serve position
                                forceLocalizationRef.current = true;
                            }
                        }}
                    />
                </Box>

                <Box sx={{ mb: 2 }}>
                    {selectedSport === "TENNIS" ? (
                        <Stack spacing={0.5}>
                            <ServePlus1LevelSelector
                                disabled={playState === "playing"}
                                label="Difficulty Level"
                                labelWrapperSx={{ flex: 1 }}
                                level={paramsState.difficultyLevel}
                                shots={appConfig.shots}
                                toggleButtonSx={{ flex: 2 }}
                                setLevel={(value) => {
                                    if (value !== null) {
                                        paramsDispatch({
                                            type: "difficultyLevel",
                                            value: value as DifficultyLevel,
                                        });
                                        modifiedRef.current = true;
                                        setSelectedShots([
                                            appConfig.shots[
                                                value as DifficultyLevel
                                            ][0].id,
                                        ]);
                                    }
                                }}
                            />
                            <ShotSelector
                                disabled={playState === "playing"}
                                selectedShotIds={selectedShots}
                                shots={
                                    appConfig.shots[paramsState.difficultyLevel]
                                }
                                onShotChange={(updated) => {
                                    setSelectedShots(updated);
                                    modifiedRef.current = true;
                                }}
                            />
                        </Stack>
                    ) : (
                        <BasicLevelSelector
                            disabled={playState === "playing"}
                            label="Difficulty Level"
                            labelWrapperSx={{ flex: 1 }}
                            level={paramsState.difficultyLevel}
                            toggleButtonSx={{ flex: 2 }}
                            setLevel={(value) => {
                                if (value !== null) {
                                    paramsDispatch({
                                        type: "difficultyLevel",
                                        value: value as DifficultyLevel,
                                    });
                                    modifiedRef.current = true;
                                    setSelectedShots(
                                        appConfig.shots[
                                            value as DifficultyLevel
                                        ].map((s) => s.id),
                                    );
                                }
                            }}
                        />
                    )}
                </Box>

                {(!status ||
                    trainerFeatures.includes("serveAndVolleyShotDelay")) && (
                    <Box sx={{ mb: 1 }}>
                        <DelaySlider
                            disabled={playState === "playing"}
                            label="Return Delay"
                            labelWrapperSx={{ flex: 1 }}
                            sliderWrapperSx={{ flex: 2 }}
                            selectedDelay={
                                workoutParams.shotDelayMilliseconds / 1000
                            }
                            onDelayChanged={(value) => {
                                paramsDispatch({ type: "shotDelay", value });
                                modifiedRef.current = true;
                            }}
                        />
                    </Box>
                )}

                <Box mb={2}>
                    <SpeedAdjustment
                        disabled={playState === "playing"}
                        value={paramsState.speedAdjustment}
                        sport="PLATFORM_TENNIS"
                        cacheKey="serve-and-volley"
                        onChange={onChangeSpeedAdjustment}
                    />
                </Box>

                <Box component="div" mb={2}>
                    <WorkflowSteps
                        currentState={
                            (workoutState?.currentState as string) ?? ""
                        }
                        selectedSport={selectedSport}
                    />
                </Box>

                <Box
                    component="div"
                    sx={{
                        paddingBottom: "80px",
                    }}
                >
                    <ResizableWorkoutVisualizer
                        workout={workoutForVisualizer}
                        positionProximity="Unavailable"
                        maxHeight={225}
                    />
                </Box>
            </Stack>

            <LocalizingDialog
                dialogOpen={localizingDialogOpen}
                onLocalized={(result) => {
                    setLocalizingDialogOpen(false);
                    if (result === "good") {
                        forceLocalizationRef.current = false;
                        void localizationSucceeded();
                    } else {
                        forceLocalizationRef.current = true;
                    }
                }}
                onCanceled={() => {
                    cancel();
                    setLocalizingDialogOpen(false);
                }}
                onUsePlanned={
                    selectedSport === "PLATFORM_TENNIS"
                        ? undefined
                        : handleUsePlanned
                }
                plannedPosition={plannedTrainerPosition}
                force={forceLocalizationRef.current}
                workout={workoutForLocalizingDialog}
                localizationHeightIn={
                    appConfig.localizationHeightIn ??
                    plannedTrainerPosition.heightIn
                }
            />

            <PlayAppBar
                onPauseClicked={handleStopWorkout}
                onPlayClicked={handlePlayClicked}
                pauseDisabled={pauseDisabled}
                playDisabled={visionUnavailable || playDisabled}
                playState={playState}
                showRecord={isAdmin()}
                onRecordClicked={() => captureVideo()}
                playSummary={summaryText}
                recordDisabled={visionUnavailable || captureDisabled}
                recordingStatus={captureStatus}
            />
            <CaptureToast captureStatus={captureStatus} />
            <LiftModal
                stop={handleLiftStop}
                targetHeight={liftTargetHeight}
                message="The trainer is adjusting the head height"
            />

            <InstructionDialog
                instructionDialogOpen={instructionDialogOpen}
                setInstructionDialogOpen={setInstructionDialogOpen}
                sport={selectedSport}
            />

            <VisionSystemStartingDialog
                open={visionErrorDialogOpen}
                setDialogOpen={setVisionErrorDialogOpen}
            />

            <ErrorDialog
                errorDialogOpen={errorDialogOpen}
                workoutStatus={workoutStatus}
                setErrorDialogOpen={async () => {
                    await handleStopWorkout();
                    setErrorDialogOpen(false);
                }}
                visualizerComponent={
                    <ResizableWorkoutVisualizer
                        workout={{
                            trainer: plannedTrainerPosition,
                            player: [servePosition],
                            shots: [],
                            AOIs: [visServeAOI, visExitAoi],
                        }}
                        positionProximity="Good"
                    />
                }
            />
        </>
    );
}

export default function ServeAndVolleyRoot(): JSX.Element {
    const [workout, setWorkout] = React.useState<AppWorkoutWithSummary | null>(
        null,
    );
    const [error, setError] = React.useState<string | null>(null);

    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>();
    const idMaybe = React.useMemo(() => parseInt(id ?? "", 10), [id]);

    const { getWorkout, addWorkout } = useAppWorkouts();
    const { physicsModelName } = usePhysicsModelContext();
    const trainerFeatures = useTrainerFeatures();
    const { status } = useStatus();
    const intercom = useIntercom();

    React.useEffect(() => {
        if (!idMaybe) {
            setError("No workout id provided");
        } else {
            getWorkout(idMaybe)
                .then((result) => {
                    if (result) {
                        setWorkout(result);
                    } else {
                        setError("Failed to load serve and volley config");
                    }
                })
                .catch((e) =>
                    logFetchError(e, "Failed to load serve and volley config"),
                );
        }
    }, [getWorkout, addWorkout, idMaybe, physicsModelName]);

    if (error) {
        return (
            <WorkoutErrorDialog
                buttonText="Back to Workouts"
                header="Workout not Found"
                text={error}
                onClick={() => {
                    navigate("/");
                }}
            />
        );
    }

    if (!workout) {
        return <Loading />;
    }

    // if we're connected to a trainer make sure we've loaded trainer feature
    // so we know if serve and volley is supported on this trainer version
    if (status && (!trainerFeatures || trainerFeatures.length === 0)) {
        return <Loading />;
    }

    if (status && !trainerFeatures.includes("serveAndVolley")) {
        return (
            <Box
                component="div"
                sx={{
                    height: "100vh",
                    display: "flex",
                    flexDirection: "column",
                    mt: 5,
                    alignItems: "center",
                    backgroundColor: "background.default",
                }}
            >
                <Typography variant="h3" mb={4}>
                    Trainer update required.
                </Typography>
                <Typography px={2} mb={4}>
                    To play the Serve and Volley app, your trainer needs to be
                    updated. Please press the icon below to message support, and
                    we will update it for you.
                </Typography>
                <IconButton
                    onClick={() => {
                        intercom.newMessage();
                    }}
                    color="primary"
                    size="large"
                >
                    <HelpIcon />
                </IconButton>
            </Box>
        );
    }

    return (
        <ServeAndVolley workout={workout} trainerFeatures={trainerFeatures} />
    );
}
