//______________________________________________________________________________
//  Default Vision Pipeline Configuration Settings by Sport
//
//  If a workout read from DB is missing these settings, these values can be
//  overlaid via overlayDefaultVisionPipelineConfig().
//
//  NOTE: Once these settings are put in the DB this mechanism can go away,
//  although it might be useful in future if we find ourselves changing
//  common settings across many serve and volley apps.
//______________________________________________________________________________
// Copyright 2025, Volley LLC
//______________________________________________________________________________

import type { BaselineDepthAppConfig } from "@volley/shared/apps/baselinedepth-models";
import type { ServeAndVolleyAppConfig } from "@volley/shared/apps/serveandvolley-models";
import {
    CoachVisionPipelineConfig,
    VisionDetectionTrackingConfig,
} from "@volley/shared/vision-models";

import logger from "../../../../../log";

// Baseline Depth Vision Pipeline Defaults
const DEFAULT_BD_VISIONCONFIGS: Record<string, CoachVisionPipelineConfig> = {
    PLATFORM_TENNIS: {
        enablePersonTracking: false,
        enablePersonFiltering: false,
        ballTrackingMode: "CLASSICAL",
    },
    TENNIS: {
        enablePersonTracking: false,
        enablePersonFiltering: false,
        ballTrackingMode: "CLASSICAL",
    },
    PICKLEBALL: {
        enablePersonTracking: false,
        enablePersonFiltering: false,
        ballTrackingMode: "CLASSICAL",
    },
    PADEL: {
        enablePersonTracking: false,
        enablePersonFiltering: false,
        ballTrackingMode: "CLASSICAL",
    },
};

// Serve and Volley Vision Pipeline Defaults
const DEFAULT_SV_VISIONCONFIGS: Record<string, CoachVisionPipelineConfig> = {
    PLATFORM_TENNIS: {
        enablePersonTracking: true,
        enablePersonFiltering: true,
        personFilteringMinX: -4.5,
        personFilteringMinY: 0,
        personFilteringMaxX: 4.5,
        personFilteringMaxY: 12.0,
        visionDetectionTrackingConfig: {
            personTrackerConfig: {
                serveDetectorEnabled: true,
                // Underhand: false until we've tested on this sport
                underhandServeDetectorEnabled: false,
            },
        },
    },
    TENNIS: {
        enablePersonTracking: true,
        enablePersonFiltering: true,
        personFilteringMinX: -7.0,
        personFilteringMinY: 0,
        personFilteringMaxX: 7.0,
        personFilteringMaxY: 24.0,
        visionDetectionTrackingConfig: {
            personTrackerConfig: {
                serveDetectorEnabled: true,
                // Underhand: false until we've tested on this sport
                underhandServeDetectorEnabled: false,
            },
        },
    },
    PICKLEBALL: {
        enablePersonTracking: true,
        enablePersonFiltering: true,
        personFilteringMinX: -4.5,
        personFilteringMinY: 0,
        personFilteringMaxX: 4.5,
        personFilteringMaxY: 12.0,
        visionDetectionTrackingConfig: {
            personTrackerConfig: {
                serveDetectorEnabled: false,
                underhandServeDetectorEnabled: true,
            },
        },
    },
    PADEL: {
        enablePersonTracking: true,
        enablePersonFiltering: true,
        personFilteringMinX: -6.5,
        personFilteringMinY: 0,
        personFilteringMaxX: 6.5,
        personFilteringMaxY: 12.0,
        visionDetectionTrackingConfig: {
            personTrackerConfig: {
                serveDetectorEnabled: false,
                underhandServeDetectorEnabled: true,
            },
        },
    },
};

//
// LEGACY: database app config may have 'visionConfig': move it to
// 'visionPipelineConfig.visionDetectionTrackingConfig'.
// NOTE: once release is complete and database entries changed this can go away.
//
function moveVisionConfigToDetectionTrackingConfig(
    appConfig: ServeAndVolleyAppConfig | BaselineDepthAppConfig,
) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((appConfig as any).visionConfig !== undefined) {
        logger.warn(
            `ServeAndVolley: copying DB legacy visionConfig to detection/tracking config (please update DB)`,
        );
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const dtConfig = (appConfig as any)
            .visionConfig as unknown as VisionDetectionTrackingConfig;
        appConfig["visionPipelineConfig"] = {};
        appConfig["visionPipelineConfig"]["visionDetectionTrackingConfig"] = {};
        appConfig.visionPipelineConfig.visionDetectionTrackingConfig = {
            ...dtConfig,
        };
        if (dtConfig.ballDetection) {
            appConfig.visionPipelineConfig.visionDetectionTrackingConfig.ballDetection =
                { ...dtConfig.ballDetection };
        }
        if (dtConfig.ballTrackerConfig) {
            appConfig.visionPipelineConfig.visionDetectionTrackingConfig.ballTrackerConfig =
                { ...dtConfig.ballTrackerConfig };
        }
        if (dtConfig.bounceDetection) {
            appConfig.visionPipelineConfig.visionDetectionTrackingConfig.bounceDetection =
                { ...dtConfig.bounceDetection };
        }
        if (dtConfig.personTrackerConfig) {
            appConfig.visionPipelineConfig.visionDetectionTrackingConfig.personTrackerConfig =
                { ...dtConfig.personTrackerConfig };
        }
        if (dtConfig.serveDetection) {
            appConfig.visionPipelineConfig.visionDetectionTrackingConfig.serveDetection =
                { ...dtConfig.serveDetection };
        }
        if (dtConfig.underhandServeDetection) {
            appConfig.visionPipelineConfig.visionDetectionTrackingConfig.underhandServeDetection =
                { ...dtConfig.underhandServeDetection };
        }
        // remove 'visionConfig'
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        delete (appConfig as any).visionConfig;
    }
}

// LEGACY: Overlay the default serve and volley config settings above on top of the config from the DB.
// NOTE: once release is complete and database entries changed this can go away.
export function overlayDefaultVisionPipelineConfig(
    appConfig: ServeAndVolleyAppConfig | BaselineDepthAppConfig,
    sport: string,
): void {
    // Move legacy 'visionConfig' if necessary
    moveVisionConfigToDetectionTrackingConfig(appConfig);
    let pcSport: CoachVisionPipelineConfig;
    // Is this Serve and Volley vs. Baseline Depth?
    if ("detectRegions" in appConfig) {
        logger.info(`Overlay BASELINEDEPTH default configs for sport ${sport}`);
        pcSport = DEFAULT_BD_VISIONCONFIGS[sport];
    } else {
        logger.info(
            `Overlay SERVEANDVOLLEY default configs for sport ${sport}`,
        );
        pcSport = DEFAULT_SV_VISIONCONFIGS[sport];
    }
    // top level
    appConfig.visionPipelineConfig = {
        ...pcSport,
        ...appConfig.visionPipelineConfig,
    };
    appConfig.visionPipelineConfig.visionDetectionTrackingConfig ??= {};
    appConfig.visionPipelineConfig.visionDetectionTrackingConfig.ballDetection ??=
        {};
    appConfig.visionPipelineConfig.visionDetectionTrackingConfig.ballTrackerConfig ??=
        {};
    appConfig.visionPipelineConfig.visionDetectionTrackingConfig.bounceDetection ??=
        {};
    appConfig.visionPipelineConfig.visionDetectionTrackingConfig.personTrackerConfig ??=
        {};
    appConfig.visionPipelineConfig.visionDetectionTrackingConfig.serveDetection ??=
        {};
    appConfig.visionPipelineConfig.visionDetectionTrackingConfig.underhandServeDetection ??=
        {};

    if (pcSport.visionDetectionTrackingConfig?.ballDetection) {
        appConfig.visionPipelineConfig.visionDetectionTrackingConfig.ballDetection =
            {
                ...pcSport.visionDetectionTrackingConfig.ballDetection,
                ...appConfig.visionPipelineConfig.visionDetectionTrackingConfig
                    .ballDetection,
            };
    }
    if (pcSport.visionDetectionTrackingConfig?.ballTrackerConfig) {
        appConfig.visionPipelineConfig.visionDetectionTrackingConfig.ballTrackerConfig =
            {
                ...pcSport.visionDetectionTrackingConfig.ballTrackerConfig,
                ...appConfig.visionPipelineConfig.visionDetectionTrackingConfig
                    .ballTrackerConfig,
            };
    }
    if (pcSport.visionDetectionTrackingConfig?.bounceDetection) {
        appConfig.visionPipelineConfig.visionDetectionTrackingConfig.bounceDetection =
            {
                ...pcSport.visionDetectionTrackingConfig.bounceDetection,
                ...appConfig.visionPipelineConfig.visionDetectionTrackingConfig
                    .bounceDetection,
            };
    }
    if (pcSport.visionDetectionTrackingConfig?.personTrackerConfig) {
        appConfig.visionPipelineConfig.visionDetectionTrackingConfig.personTrackerConfig =
            {
                ...pcSport.visionDetectionTrackingConfig.personTrackerConfig,
                ...appConfig.visionPipelineConfig.visionDetectionTrackingConfig
                    .personTrackerConfig,
            };
    }
    if (pcSport.visionDetectionTrackingConfig?.serveDetection) {
        appConfig.visionPipelineConfig.visionDetectionTrackingConfig.serveDetection =
            {
                ...pcSport.visionDetectionTrackingConfig.serveDetection,
                ...appConfig.visionPipelineConfig.visionDetectionTrackingConfig
                    .serveDetection,
            };
    }
    if (pcSport.visionDetectionTrackingConfig?.underhandServeDetection) {
        appConfig.visionPipelineConfig.visionDetectionTrackingConfig.underhandServeDetection =
            {
                ...pcSport.visionDetectionTrackingConfig
                    .underhandServeDetection,
                ...appConfig.visionPipelineConfig.visionDetectionTrackingConfig
                    .underhandServeDetection,
            };
    }
}
