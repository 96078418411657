import * as React from "react";
import { Link as RouterLink } from "react-router-dom";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import {
    useSelectedSport,
    type Sport,
} from "../../../../../common/context/sport";

interface LinkButton {
    label: string;
    url: string;
}

const defaultButtons = [
    {
        label: "Net-Play",
        url: "/content/apps/workouts/experimental/basic/play?tag=BASIC_FRONTCOURT_SIDE",
    },
    {
        label: "Back Court",
        url: "/content/apps/workouts/experimental/basic/play?tag=BASIC_BACKCOURT",
    },
];

const ButtonsBySport: Record<Sport, LinkButton[]> = {
    PADEL: defaultButtons,
    PICKLEBALL: defaultButtons,
    PLATFORM_TENNIS: defaultButtons,
    TENNIS: [
        {
            label: "Returns",
            url: "/content/apps/workouts/experimental/basic/play?tag=BASIC_FRONTCOURT_SIDE",
        },
        {
            label: "Full Court",
            url: "/content/apps/workouts/experimental/basic/play?tag=BASIC_BACKCOURT",
        },
    ],
};

export default function BasicHomeScreenItems(): JSX.Element | null {
    const { selected: sport } = useSelectedSport();

    if (ButtonsBySport[sport].length === 0) {
        return null;
    }

    return (
        <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={1}
        >
            <Typography color="secondary" variant="h5" component="h2">
                Quick Start
            </Typography>
            <ButtonGroup>
                {ButtonsBySport[sport].map((b) => (
                    <Button
                        key={`${sport}-${b.label}`}
                        variant="outlined"
                        color="secondary"
                        size="small"
                        endIcon={<ChevronRightIcon />}
                        component={RouterLink}
                        to={b.url}
                        sx={{
                            fontSize: "0.75rem",
                            "& > .MuiButton-endIcon": {
                                ml: "2px",
                            },
                        }}
                    >
                        {b.label}
                    </Button>
                ))}
            </ButtonGroup>
        </Stack>
    );
}
