import * as React from "react";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { convertMetersPerSecondToMPH } from "../../../../../util/unitConversion";

import { Serve } from "./Court";

function resultFromServe(serve: Serve): string {
    let result = "Out";

    if (serve.inAoi) {
        result = "In";
    } else if (serve.inServiceBox) {
        result = "Miss";
    }

    return result;
}

export default function ResultsTable({
    results,
}: {
    results: Serve[] | undefined;
}) {
    if (!results || results.length === 0) {
        return null;
    }
    return (
        <TableContainer component={Paper} sx={{ mt: 2 }}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Result</TableCell>
                        <TableCell>Speed</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {results.map((result, index) => (
                        <TableRow key={index}>
                            <TableCell>{resultFromServe(result)}</TableCell>
                            <TableCell>
                                {result.speed !== undefined
                                    ? `${Math.round(
                                          convertMetersPerSecondToMPH(
                                              result.speed,
                                          ),
                                      )} MPH`
                                    : "—"}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
