import { Vector3 } from "three";

import { AppWorkoutWithSummary as AppWorkout } from "@volley/data";
import type { CourtPoint } from "@volley/physics/dist/models";
import {
    LevelNumber,
    LeveledWorkoutConfig,
} from "@volley/shared/apps/curated-workout-models";
import type { ModuleConfig } from "@volley/shared/apps/module-models";
import type {
    SingleShotConfig,
    SingleShotShot,
} from "@volley/shared/apps/single-shot-models";
import {
    PlayMode,
    PlayerPosition,
} from "@volley/shared/dist/apps/app-common-models";
import type { CuratedWorkoutConfig } from "@volley/shared/dist/apps/curated-workout-models";

import {
    mirrorTrainer,
    mirrorPlayer,
    mirrorShot,
} from "../../Trainer/Position/util";
import { Sport } from "../context/sport";

import type { WorkoutForVisualizer } from "./types";

export function multiLevelToVisualizer(
    workout: AppWorkout,
    level: LevelNumber,
    playMode: PlayMode,
    selectedSport: Sport,
): WorkoutForVisualizer {
    const { config } = workout;

    const trainer =
        playMode === "mirror"
            ? mirrorTrainer(
                  {
                      heightIn: workout.positionHeight,
                      x: workout.positionX,
                      y: workout.positionY,
                      yaw: workout.positionYaw,
                  },
                  selectedSport,
              )
            : {
                  heightIn: workout.positionHeight,
                  x: workout.positionX,
                  y: workout.positionY,
                  yaw: workout.positionYaw,
              };

    let player: PlayerPosition[] = [];
    const { playerPosition } = config as unknown as LeveledWorkoutConfig;
    if (playerPosition) {
        switch (playMode) {
            case "mirror":
                player = [mirrorPlayer(playerPosition, selectedSport)];
                break;
            case "dual":
                player = [
                    mirrorPlayer(playerPosition, selectedSport),
                    {
                        x: playerPosition.x,
                        y: playerPosition.y,
                    },
                ];
                break;
            default:
                player = [
                    {
                        x: playerPosition.x,
                        y: playerPosition.y,
                    },
                ];
                break;
        }
    }

    const { shots } = (config as unknown as LeveledWorkoutConfig).levels[level];
    let shotsToRender = [...shots];
    if (playMode === "mirror") {
        shotsToRender = shots.map((s) => mirrorShot(s));
    } else if (playMode === "dual") {
        shotsToRender.push(...shots.map((s) => mirrorShot(s)));
    }

    const transformed = {
        player,
        shots: shotsToRender,
        trainer,
    };

    return transformed;
}

export function moduleToVisualizer(
    workout: AppWorkout,
    playMode: PlayMode,
    selectedSport: Sport,
): WorkoutForVisualizer {
    const { config } = workout;

    const trainer =
        playMode === "mirror"
            ? mirrorTrainer(
                  {
                      heightIn: workout.positionHeight,
                      x: workout.positionX,
                      y: workout.positionY,
                      yaw: workout.positionYaw,
                  },
                  selectedSport,
              )
            : {
                  heightIn: workout.positionHeight,
                  x: workout.positionX,
                  y: workout.positionY,
                  yaw: workout.positionYaw,
              };

    let player: PlayerPosition[] = [];
    const { playerPosition } = config as unknown as ModuleConfig;
    if (playerPosition) {
        switch (playMode) {
            case "mirror":
                player = [mirrorPlayer(playerPosition, selectedSport)];
                break;
            case "dual":
                player = [
                    mirrorPlayer(playerPosition, selectedSport),
                    {
                        x: playerPosition.x,
                        y: playerPosition.y,
                    },
                ];
                break;
            default:
                player = [
                    {
                        x: playerPosition.x,
                        y: playerPosition.y,
                    },
                ];
                break;
        }
    }

    const { shots } = config as unknown as ModuleConfig;
    let shotsToRender = [...shots];
    if (playMode === "mirror") {
        shotsToRender = shots.map((s) => mirrorShot(s));
    } else if (playMode === "dual") {
        shotsToRender.push(...shots.map((s) => mirrorShot(s)));
    }

    const transformed = {
        player,
        shots: shotsToRender,
        trainer,
    };

    return transformed;
}

export function singleShotToVisualizer(
    workout: AppWorkout,
    playMode: PlayMode,
    selectedSport: Sport,
): WorkoutForVisualizer {
    const { config } = workout;

    const trainer =
        playMode === "mirror"
            ? mirrorTrainer(
                  {
                      heightIn: workout.positionHeight,
                      x: workout.positionX,
                      y: workout.positionY,
                      yaw: workout.positionYaw,
                  },
                  selectedSport,
              )
            : {
                  heightIn: workout.positionHeight,
                  x: workout.positionX,
                  y: workout.positionY,
                  yaw: workout.positionYaw,
              };

    const { playerPosition } = config as unknown as SingleShotConfig;
    let player: PlayerPosition[] = [];
    let shots: SingleShotShot[] = [];
    switch (playMode) {
        case "mirror":
            if (playerPosition)
                player = [mirrorPlayer(playerPosition, selectedSport)];
            shots = [mirrorShot((config as unknown as SingleShotConfig).shot)];
            break;
        case "dual":
            if (playerPosition) {
                player = [
                    mirrorPlayer(playerPosition, selectedSport),
                    {
                        x: playerPosition.x,
                        y: playerPosition.y,
                    },
                ];
            }
            shots = [
                (config as unknown as SingleShotConfig).shot,
                mirrorShot((config as unknown as SingleShotConfig).shot),
            ];
            break;
        default:
            if (playerPosition) {
                player = [
                    {
                        x: playerPosition.x,
                        y: playerPosition.y,
                    },
                ];
            }
            shots = [(config as unknown as SingleShotConfig).shot];
            break;
    }

    const transformed = {
        player,
        shots,
        trainer,
    };

    return transformed;
}

export function multiShotToVisualizer(
    workout: AppWorkout,
    playMode: PlayMode,
    selectedSport: Sport,
): WorkoutForVisualizer {
    const { config } = workout;

    const trainer =
        playMode === "mirror"
            ? mirrorTrainer(
                  {
                      heightIn: workout.positionHeight,
                      x: workout.positionX,
                      y: workout.positionY,
                      yaw: workout.positionYaw,
                  },
                  selectedSport,
              )
            : {
                  heightIn: workout.positionHeight,
                  x: workout.positionX,
                  y: workout.positionY,
                  yaw: workout.positionYaw,
              };

    let player: PlayerPosition[] = [];
    const { playerPosition } = config as unknown as CuratedWorkoutConfig;
    if (playerPosition) {
        switch (playMode) {
            case "mirror":
                player = [mirrorPlayer(playerPosition, selectedSport)];
                break;
            case "dual":
                player = [
                    mirrorPlayer(playerPosition, selectedSport),
                    {
                        x: playerPosition.x,
                        y: playerPosition.y,
                    },
                ];
                break;
            default:
                player = [
                    {
                        x: playerPosition.x,
                        y: playerPosition.y,
                    },
                ];
                break;
        }
    }

    const { shots } = config as unknown as CuratedWorkoutConfig;
    let shotsToRender = [...(shots ?? [])];
    if (playMode === "mirror") {
        shotsToRender = (shots ?? []).map((s) => mirrorShot(s));
    } else if (playMode === "dual") {
        shotsToRender.push(...(shots ?? []).map((s) => mirrorShot(s)));
    }

    const transformed = {
        player,
        shots: shotsToRender,
        trainer,
    };

    return transformed;
}

export function workoutToVisualizer(
    workout: AppWorkout | undefined,
    playMode: PlayMode,
    sport: Sport,
): WorkoutForVisualizer | undefined {
    if (!workout) {
        return undefined;
    }

    switch (workout.appId) {
        case 11:
            return moduleToVisualizer(workout, playMode, sport);
        case 4:
            return multiShotToVisualizer(workout, playMode, sport);
        case 5:
            return singleShotToVisualizer(workout, playMode, sport);
        case 9:
            return multiLevelToVisualizer(workout, 1, playMode, sport);
        default:
            return undefined;
    }
}

export function localization2visualizer(viz: Vector3 | CourtPoint): Vector3 {
    return new Vector3(viz.x, viz.z, -viz.y);
}
