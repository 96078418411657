import * as React from "react";

import { AccordionProps } from "@mui/material/Accordion";
import { OrbitControls } from "@react-three/drei";
import { useThree, useFrame } from "@react-three/fiber";
import * as THREE from "three";
import type { OrbitControls as OrbitControlsImpl } from "three-stdlib";

import * as convert from "@volley/physics/dist/conversions";
import { TrainerPosition } from "@volley/physics/dist/models";

import { useSelectedSport } from "../context/sport";

import type { CameraView, CameraViewStatus } from "./types";

interface CameraOrbitControlsProps extends Omit<AccordionProps, "children"> {
    cameraViewStatus: CameraViewStatus;
    setCameraViewStatus: React.Dispatch<React.SetStateAction<CameraViewStatus>>;
    requestedView: CameraView;
    trainerPositions: TrainerPosition[];
    enableCameraControl?: boolean;
}

const MAX_RADIAL_DISTANCE = 20; // Max radial distance from the camera to the scene origin(0, 0, 0)
const MIN_CAM_Y = 0.1; // Min camera position above the ground
const SCENE_ORIGIN = new THREE.Vector3(0, 0, 0);
const SCENE_DIR = new THREE.Vector3(0, 0, 0); // Directional vector towards the scene origin
const WORLD_DIR = new THREE.Vector3(); // Directional vector of the current camera view

// Default camera position offsets
const X_OFFSET = -1.5;
const Y_OFFSET = 2.5;
const Z_OFFSET = 3.5;

export default function CameraOrbitControls({
    cameraViewStatus,
    setCameraViewStatus,
    requestedView,
    trainerPositions,
    enableCameraControl = true,
}: CameraOrbitControlsProps): JSX.Element {
    const { selected: selectedSport } = useSelectedSport();
    const controlsRef = React.useRef<OrbitControlsImpl>(null!);
    const { camera } = useThree();
    const cameraLocation = React.useMemo(() => {
        switch (requestedView) {
            case "person": {
                const [trainer] = trainerPositions;
                const { courtGeometry } = convert.getPhysicsModel();
                const z = courtGeometry.COURT_LENGTH;
                return {
                    position: new THREE.Vector3(0, 3, -z),
                    target: new THREE.Vector3(trainer.x, trainer.h, -trainer.y),
                };
            }
            case "bird": {
                const [trainer, ghost] = trainerPositions;
                const position = ghost
                    ? new THREE.Vector3(
                          (ghost.x + trainer.x) / 2,
                          6,
                          (ghost.y + trainer.y) / 2,
                      )
                    : new THREE.Vector3(trainer.x, 6, trainer.y);
                return {
                    position,
                    target: new THREE.Vector3(position.x, 0, 0),
                };
            }
            case "behindTrainer": {
                const [trainer] = trainerPositions;
                const trainerPosition = new THREE.Vector3(
                    trainer.x,
                    trainer.h,
                    -trainer.y,
                );
                const direction = new THREE.Vector3(
                    Math.sin(trainer.yaw),
                    0,
                    -Math.cos(trainer.yaw),
                );
                const position = new THREE.Vector3(
                    trainerPosition.x,
                    trainerPosition.y + Y_OFFSET + 2,
                    trainerPosition.z + Z_OFFSET,
                );
                const target = trainerPosition
                    .clone()
                    .add(direction.multiplyScalar(5));
                return {
                    position,
                    target,
                };
            }
            case "topDown": {
                const { courtGeometry } = convert.getPhysicsModel();
                if (selectedSport === "TENNIS") {
                    return {
                        position: new THREE.Vector3(
                            0,
                            courtGeometry.COURT_LENGTH * 0.6,
                            courtGeometry.COURT_LENGTH * 0.4,
                        ),
                        target: new THREE.Vector3(
                            0,
                            0,
                            courtGeometry.COURT_LENGTH * 0.35,
                        ),
                    };
                }
                return {
                    position: new THREE.Vector3(
                        0,
                        courtGeometry.COURT_LENGTH * 0.75,
                        courtGeometry.COURT_LENGTH * 0.5,
                    ),
                    target: new THREE.Vector3(
                        0,
                        0,
                        courtGeometry.COURT_LENGTH * 0.35,
                    ),
                };
            }
            case "default":
            default: {
                const [trainer] = trainerPositions;
                const { courtGeometry } = convert.getPhysicsModel();
                const x = trainer.x + X_OFFSET;
                const y = trainer.h + Y_OFFSET;
                const z = -trainer.y + Z_OFFSET;
                return {
                    position: new THREE.Vector3(x, y, z),
                    target: new THREE.Vector3(
                        0,
                        0,
                        -courtGeometry.SERVICE_LENGTH * 0.333,
                    ),
                };
            }
        }
    }, [requestedView, trainerPositions, selectedSport]);

    const firstRender = React.useRef(true);
    useFrame(({ camera: stateCamera }, delta) => {
        if (cameraViewStatus !== "requested") return;
        stateCamera.position.lerp(cameraLocation.position, delta * 2);
        controlsRef.current.target.lerp(cameraLocation.target, delta * 2);
        if (
            Math.abs(
                stateCamera.position.distanceTo(cameraLocation.position),
            ) <= 0.1 ||
            firstRender.current
        ) {
            firstRender.current = false;
            stateCamera.position.copy(cameraLocation.position);
            controlsRef.current.target.copy(cameraLocation.target);
            setCameraViewStatus("reached");
        }
    });

    const onOrbitAction = React.useCallback(() => {
        if (
            Math.abs(camera.position.distanceTo(cameraLocation.position)) > 0.1
        ) {
            setCameraViewStatus("away");
        }
    }, [camera.position, cameraLocation.position, setCameraViewStatus]);

    // Handles clipping of the camera position that with enabled panning could easily get out of control
    const onOrbitChange = React.useCallback(() => {
        const controls = controlsRef.current;
        // Clip the camera position based on the radial distance to the scene origin
        const delta =
            camera.position.distanceTo(SCENE_ORIGIN) - MAX_RADIAL_DISTANCE;
        if (delta > 0) {
            SCENE_DIR.subVectors(camera.position, SCENE_ORIGIN).normalize();
            camera.position.addVectors(
                camera.position,
                SCENE_DIR.multiplyScalar(-delta),
            );
        }

        camera.position.set(
            camera.position.x,
            Math.max(camera.position.y, MIN_CAM_Y),
            camera.position.z,
        );

        // Maintain the target 4 meters (picked arbitrary) from the camera for "walking" experience with zoom
        const zoomDistance = controls.target.distanceTo(camera.position) - 4;
        if (Math.abs(zoomDistance) > 0.1) {
            camera.getWorldDirection(WORLD_DIR);
            controls.target.addVectors(
                controls.target,
                WORLD_DIR.multiplyScalar(-zoomDistance),
            );
        }
    }, [camera]);

    return (
        <OrbitControls
            mouseButtons={enableCameraControl ? undefined : {}}
            touches={enableCameraControl ? undefined : {}}
            onEnd={onOrbitAction}
            onStart={onOrbitAction}
            onChange={onOrbitChange}
            enableDamping={cameraViewStatus !== "requested"}
            enableZoom
            zoomSpeed={2}
            enablePan
            panSpeed={4}
            enableRotate
            target={cameraLocation.target}
            position={cameraLocation.position}
            ref={controlsRef}
        />
    );
}
