import * as React from "react";

import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Input from "@mui/material/Input";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import type { Shot } from "@volley/shared/apps/app-common-models";

import { pluralize } from "../../../../../util/text";

interface Props {
    selectedShotIds: string[];
    shots: Shot[];
    onShotChange: (updated: string[]) => void;
    disabled: boolean;
}

export default function ShotSelector({
    selectedShotIds,
    shots,
    onShotChange,
    disabled,
}: Props): JSX.Element {
    const [open, setOpen] = React.useState(false);
    const label = pluralize(selectedShotIds.length, "Return Shot");
    return (
        <Stack direction="row" spacing={1} alignItems="center">
            <Box component="div">
                <Typography variant="caption" sx={{ minWidth: "30px" }}>
                    {label}
                </Typography>
            </Box>
            <Box component="div" flexGrow={1}>
                <Select
                    disabled={disabled}
                    labelId="shot-selector-label"
                    value={selectedShotIds}
                    onChange={(event) => {
                        if (event.target.value.length === 0) {
                            return;
                        }
                        onShotChange(event.target.value as string[]);
                    }}
                    label={label}
                    multiple
                    open={open}
                    input={
                        <Input
                            id="select-multiple-chip"
                            fullWidth
                            disabled={disabled}
                            disableUnderline
                            sx={{
                                backgroundColor: "white",
                                borderRadius: "10px",
                                padding: "2px",
                                minHeight: "45px",
                                borderWidth: "1px",
                                borderStyle: "solid",
                                borderColor: (t) => t.palette.primary.dark,
                            }}
                            onClick={() => {
                                if (!disabled) {
                                    setOpen(!open);
                                }
                            }}
                        />
                    }
                    renderValue={(selected) => (
                        <Stack direction="row" gap={1} flexWrap="wrap">
                            {shots
                                .filter((s) => selected.includes(s.id))
                                .map((value) => (
                                    <Chip
                                        key={value.id}
                                        disabled={disabled}
                                        label={value.name}
                                        sx={{
                                            minWidth: "80px",
                                        }}
                                        onDelete={() => {
                                            if (selected.length === 1) {
                                                return;
                                            }
                                            const updated =
                                                selectedShotIds.filter(
                                                    (s) => s !== value.id,
                                                );
                                            onShotChange(updated);
                                        }}
                                    />
                                ))}
                        </Stack>
                    )}
                >
                    {shots.map((shot) => (
                        <MenuItem key={shot.id} value={shot.id}>
                            {shot.name ?? shot.id}
                        </MenuItem>
                    ))}
                </Select>
            </Box>
        </Stack>
    );
}
