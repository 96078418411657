"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tableTrainerGeometryMap = void 0;
/* eslint-disable import/prefer-default-export */
/* eslint-disable import/prefer-default-export */
/* eslint-disable quote-props */
/* eslint-disable max-len */
/*
    Trainer-specific geometry
*/
const models_1 = require("./models");
const GEOMETRY_ATOM_20B = {
    cameraOffsetFromHead: {
        x: -2.36 * models_1.metersPerInch,
        y: 11.77 * models_1.metersPerInch,
        z: 32.07 * models_1.metersPerInch,
    },
    cameraDownwardPitch: 7.0,
    secondaryCameraOffsetFromHead: {
        x: 6.252 * models_1.metersPerInch,
        y: 11.776 * models_1.metersPerInch,
        z: 6.308 * models_1.metersPerInch,
    },
    secondaryCameraDownwardPitch: 24.0,
    liftOrigin: {
        x: 0 * models_1.metersPerInch,
        y: (-5.63 + 37.5) * models_1.metersPerInch,
        z: 42.86 * models_1.metersPerInch,
    },
    liftPivotPosition: {
        x: 0 * models_1.metersPerInch,
        y: -5.63 * models_1.metersPerInch,
        z: 42.86 * models_1.metersPerInch,
    },
    liftArmLength: 37.5 * models_1.metersPerInch,
    defaultLaunchPointOffset: {
        x: 0 * models_1.metersPerInch,
        y: 12.89 * models_1.metersPerInch,
        z: 17.75 * models_1.metersPerInch,
    },
    // launchOriginOffset: offset of launch coord system origin from lift arm end
    // NOTE: origin is launchDistanceFromRotationCenter behind the default
    // launch point in Y.
    launchOriginOffset: {
        x: 0 * models_1.metersPerInch,
        y: (12.89 - 2.8) * models_1.metersPerInch,
        z: 17.75 * models_1.metersPerInch,
    },
    // distance from launch assembly center of rotation to launch point
    launchDistanceFromRotationCenter: 2.8 * models_1.metersPerInch,
};
const GEOMETRY_ATOM_20CDEF = {
    cameraOffsetFromHead: {
        x: -2.36 * models_1.metersPerInch,
        y: 11.77 * models_1.metersPerInch,
        z: 32.07 * models_1.metersPerInch,
    },
    cameraDownwardPitch: 7.0,
    secondaryCameraOffsetFromHead: {
        x: 7.935 * models_1.metersPerInch,
        y: 12.72 * models_1.metersPerInch,
        z: 4.884 * models_1.metersPerInch,
    },
    secondaryCameraDownwardPitch: 24.0,
    liftOrigin: {
        x: 0 * models_1.metersPerInch,
        y: (-5.63 + 37.5) * models_1.metersPerInch,
        z: 42.86 * models_1.metersPerInch,
    },
    liftPivotPosition: {
        x: 0 * models_1.metersPerInch,
        y: -5.63 * models_1.metersPerInch,
        z: 42.86 * models_1.metersPerInch,
    },
    liftArmLength: 37.5 * models_1.metersPerInch,
    defaultLaunchPointOffset: {
        x: 0 * models_1.metersPerInch,
        y: 12.89 * models_1.metersPerInch,
        z: 17.75 * models_1.metersPerInch,
    },
    // launchOriginOffset: offset of launch coord system origin from lift arm end
    // NOTE: origin is launchDistanceFromRotationCenter behind the default
    // launch point in Y.
    launchOriginOffset: {
        x: 0 * models_1.metersPerInch,
        y: (12.89 - 2.8) * models_1.metersPerInch,
        z: 17.75 * models_1.metersPerInch,
    },
    // distance from launch assembly center of rotation to launch point
    launchDistanceFromRotationCenter: 2.8 * models_1.metersPerInch,
};
const GEOMETRY_ATOM_30A = {
    cameraOffsetFromHead: {
        x: -2.36 * models_1.metersPerInch,
        y: 11.77 * models_1.metersPerInch,
        z: 32.07 * models_1.metersPerInch,
    },
    cameraDownwardPitch: 7.0,
    secondaryCameraOffsetFromHead: {
        x: 7.935 * models_1.metersPerInch,
        y: 12.72 * models_1.metersPerInch,
        z: 4.884 * models_1.metersPerInch,
    },
    secondaryCameraDownwardPitch: 24.0,
    liftOrigin: {
        x: 0 * models_1.metersPerInch,
        y: (-5.63 + 37.5) * models_1.metersPerInch,
        z: 42.86 * models_1.metersPerInch,
    },
    liftPivotPosition: {
        x: 0 * models_1.metersPerInch,
        y: -5.63 * models_1.metersPerInch,
        z: 42.86 * models_1.metersPerInch,
    },
    liftArmLength: 37.5 * models_1.metersPerInch,
    defaultLaunchPointOffset: {
        x: 0 * models_1.metersPerInch,
        y: 12.89 * models_1.metersPerInch,
        z: 17.75 * models_1.metersPerInch,
    },
    // launchOriginOffset: offset of launch coord system origin from lift arm end
    // NOTE: origin is launchDistanceFromRotationCenter behind the default
    // launch point in Y.
    launchOriginOffset: {
        x: 0 * models_1.metersPerInch,
        y: (12.89 - 2.8) * models_1.metersPerInch,
        z: 17.75 * models_1.metersPerInch,
    },
    // distance from launch assembly center of rotation to launch point
    launchDistanceFromRotationCenter: 2.8 * models_1.metersPerInch,
};
const GEOMETRY_ATOM_30B = {
    // NOTE: the only change from 30A to 30B is the rollbar (and thus camera)
    // is pushed forward 3 inches.
    cameraOffsetFromHead: {
        x: -2.36 * models_1.metersPerInch,
        y: 14.77 * models_1.metersPerInch,
        z: 32.07 * models_1.metersPerInch,
    },
    cameraDownwardPitch: 7.0,
    secondaryCameraOffsetFromHead: {
        x: 7.935 * models_1.metersPerInch,
        y: 12.72 * models_1.metersPerInch,
        z: 4.884 * models_1.metersPerInch,
    },
    secondaryCameraDownwardPitch: 24.0,
    liftOrigin: {
        x: 0 * models_1.metersPerInch,
        y: (-5.63 + 37.5) * models_1.metersPerInch,
        z: 42.86 * models_1.metersPerInch,
    },
    liftPivotPosition: {
        x: 0 * models_1.metersPerInch,
        y: -5.63 * models_1.metersPerInch,
        z: 42.86 * models_1.metersPerInch,
    },
    liftArmLength: 37.5 * models_1.metersPerInch,
    defaultLaunchPointOffset: {
        x: 0 * models_1.metersPerInch,
        y: 12.89 * models_1.metersPerInch,
        z: 17.75 * models_1.metersPerInch,
    },
    // launchOriginOffset: offset of launch coord system origin from lift arm end
    // NOTE: origin is launchDistanceFromRotationCenter behind the default
    // launch point in Y.
    launchOriginOffset: {
        x: 0 * models_1.metersPerInch,
        y: (12.89 - 2.8) * models_1.metersPerInch,
        z: 17.75 * models_1.metersPerInch,
    },
    // distance from launch assembly center of rotation to launch point
    launchDistanceFromRotationCenter: 2.8 * models_1.metersPerInch,
};
exports.tableTrainerGeometryMap = {
    "physics-20B-base": GEOMETRY_ATOM_20B,
    "physics-20C-base": GEOMETRY_ATOM_20CDEF,
    "physics-20D-base": GEOMETRY_ATOM_20CDEF,
    "physics-20E-base": GEOMETRY_ATOM_20CDEF,
    "physics-20F-base": GEOMETRY_ATOM_20CDEF,
    "physics-30A-base": GEOMETRY_ATOM_30A,
    "physics-30B-base": GEOMETRY_ATOM_30B,
};
